<template>
	<div v-loading="loading">
		<div class="item-block">
			<div class="payment-detail">
				<div class="payment-media">
					<el-row>
						<el-col :span="16">
							<div class="media-body">
								<el-row>
									<el-col :span="12">
										<div class="payment-text">{{$lang('payment_text')}}</div>
										<div>
											<span>{{$lang('amount_payable')}}：</span>
											<span class="payment-money ns-text-color">{{ siteInfo.price_unit }}{{ payInfo.pay_money }}</span>
										</div>
									</el-col>
									<el-col :span="12"></el-col>
								</el-row>
							</div>
						</el-col>

						<el-col :span="4">
							<div class="media-right">
								<div class="el-button--text" @click="orderOpen ? (orderOpen = false) : (orderOpen = true)">
									{{$lang('order_information')}}
									<i :class="orderOpen ? 'rotate' : ''" class="el-icon-arrow-down"></i>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>

				<div class="order-info" v-if="orderOpen">
					<el-row>
						<el-col :span="20">
							<div class="line"></div>
							<div class="order-item">
								<div class="item-label">{{$lang('transaction_no')}}：</div>
								<div class="item-value">{{ payInfo.out_trade_no }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">{{$lang('order_content')}}：</div>
								<div class="item-value">{{ payInfo.pay_detail }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">{{$lang('order_amount')}}：</div>
								<div class="item-value">{{ siteInfo.price_unit }}{{ payInfo.pay_money }}</div>
							</div>
							<div class="order-item">
								<div class="item-label">{{$lang('create_time')}}：</div>
								<div class="item-value">{{ $timeStampTurnTime(payInfo.create_time) }}</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>

		<div class="item-block">
			<div class="block-text">{{$lang('payment_method')}}</div>
			<div class="pay-type-list" v-if="payTypeList.length">
				<div v-for="(item, index) in payTypeList" :key="index">
					<div @click="changePayType(item,index)" class="pay-type-item" :class="payIndex == index ? 'active' : ''">
						<i class="iconfont icon-checkbox"></i>
						<i class="iconfont icon-fuxuankuanggou"></i>
						<i class="iconfont" :class="item.icon"></i>
						<span> {{ item.name }}</span>	
						<span class="pay-desc"> {{ item.desc }}</span>
					</div>
					
					<div v-if="item.type == 'transfer'" class="transfer-payment" :style="{display:payType=='transfer'?'block':'none'}">
						{{$lang('transfer_one')}}<br />
						{{$lang('transfer_two')}}<br />
						{{ payInfo.bank_name }}<br />
						{{ payInfo.partner_code }}<br />
						{{ payInfo.credential_code }}<br />
						<span style="color:#ea4d19">{{$lang('transfer_three')}}</span><br />
						{{$lang('transfer_four')}}
					</div>
				</div>
				<div class="clear"></div>
			</div>
			<div class="no-pay-type" v-else>
				<p>商家未配置支付方式</p>
			</div>
		</div>

		<div class="item-block" v-if="payTypeList.length">
			<div class="order-submit"><el-button type="primary" class="el-button--primary" @click="pay">立即支付</el-button></div>
			<div class="clear"></div>
		</div>

		<el-dialog :title="$lang('confirm_pay')" :visible.sync="dialogVisible" width="23%" top="30vh" class="confirm-pay-wrap">
			<div class="info-wrap">
				<i class="el-message-box__status el-icon-warning"></i>
				<span>{{$lang('confirm_pay_c')}}</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="otherPayment" size="small">{{$lang('choose_pay')}}</el-button>
				<el-button type="primary" @click="goOrderList" size="small">{{$lang('payment_completed')}}</el-button>
			</span>
		</el-dialog>

		<!-- 微信支付弹框 -->
		<el-dialog title="请用微信扫码支付" :visible.sync="openQrcode" width="300px" center>
			<div class="wechatpay-box"><img :src="payQrcode" /></div>
		</el-dialog>
	</div>
</template>

<script>
	import {getPayInfo, getPayType, checkPayStatus, pay} from '@/api/pay';
	import { setToken } from "@/utils/auth"
	import { showPayLoading, hideLoading } from '@/utils/loading';
	import { mapGetters } from 'vuex';

	export default {
		name: 'pay',
		components: {},
		data: () => {
			return {
				orderOpen: true,
				outTradeNo: '',
				payInfo: {
					pay_money: 0
				},
				payIndex: 0,
				payTypeList: [	
					{
						name: vue.$lang('credit_cards'),
						icon: 'icon-xinyongka',
						type: 'stripe',
						desc: vue.$lang('credit_cards_trip')
					},	
					{
						name: vue.$lang('paypal'),
						icon: 'icon-paypal',
						type: 'paypal',
						desc: ''
					},
					{
						name: vue.$lang('wechat_pay'),
						icon: 'icon-weixinzhifu',
						type: 'omipaywechat',
						desc: ''
					},
					{
						name: vue.$lang('alipay'),
						icon: 'icon-zhifubao',
						type: 'omipayalipay',
						desc: ''
					},			
					{
						name: vue.$lang('eft_bank'),
						icon: 'icon-xinyongka',
						type: 'transfer',
						desc: ''
					},
				],
				payUrl: '',
				timer: null,
				payQrcode: '',
				openQrcode: false,
				loading: true,
				test: null,
				dialogVisible: false,
				payType: 'stripe',
				stripe: null,
				cardElement: null,
				stripe_save_card: false,
				stripeObj: {},
				isDisablePayButton: false,
				showStripe: 0,
				paybutton: vue.$lang('pay_button')
			};
		},
		mounted() {
			/**
			 * var cont = document.body
				var newScript = document.createElement('script');
				newScript.type = 'text/javascript';
				newScript.src = "https://js.stripe.com/v3/";
				cont.appendChild(newScript);
			 */
		},
		updated() {
			/**
			 * if(this.showStripe == 0 && this.payType == 'stripe'){
				this.showStripe++;
				this.addStripePay(this.payInfo['stripe_key']); //发起网络请求
			}
			 */
		},
		created() {
			if (!this.$route.query.code) {
				this.$router.push({
					path: '/'
				});
				return;
			}
			
			this.outTradeNo = this.$route.query.code;
			if(this.$route.query.authenticate) {
				this.$store.dispatch("member/logout")
				localStorage.setItem('website_uuid', this.$route.query.authenticate)
			}
			this.getPayInfo();
			this.getPayType();
			this.checkPayStatus();
		},
		destroyed() {
            window.clearInterval(this.timer) //停止计时器
		},
		beforeDestroy(){
			// 页面离开时断开连接,清除定时器
			window.clearInterval(this.timer) //停止计时器
		},
		computed:{
			...mapGetters(['siteInfo', "member"]),
			logined: function() {
				return this.member !== undefined && this.member !== "" && this.member !== {}
			}
		},
		methods: {
			//加载信用卡支付信息
			addStripePay(stripe_key){
				this.stripe = Stripe("pk_live_51Hp3HsJZA4HNt6w5z2Os5d6W1FW28nHrrGMjk3CpNudK278piOxsKp25bs1UWX3VGZ0CN6fXY9N49K4lhigoGhJ400ymxJwdM9");
				
				const elements = this.stripe.elements();
				const style = {
					base: {
						color: "#32325d",
						fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
						fontSmoothing: "antialiased",
						fontSize: "16px",
						"::placeholder": {
							color: "#aab7c4"
						}
					},
					invalid: {
						color: "#fa755a",
						iconColor: "#fa755a"
					},
				};

				this.cardElement = elements.create('card', {style});
				this.cardElement.mount('#card-element');
				this.loading = false;
				this.isDisablePayButton = false;
			},
			//信用卡支付
			async stripePay(){
				if (this.isDisablePayButton) {
					return false;
				}
				showPayLoading();

				this.isDisablePayButton = true;

				const result = await this.stripe.createPaymentMethod({
					type: 'card',
					card: this.cardElement
				})

				if (result.error) {
					hideLoading();
					this.$alert(result.error.message, '支付失败', {
						confirmButtonText: '确定',
						callback: action => {
							
						}
					});
					this.isDisablePayButton = false;
				} else {
					var is_save_card = this.stripe_save_card ? 1 : 0;
					var params = {
						payment_method_id: result.paymentMethod.id,
						is_save_card: is_save_card,
						out_trade_no: this.payInfo.out_trade_no,
						pay_type: this.payType,
						app_type: 'pc'
					};
					if (!this.logined) {
						if(localStorage.getItem('website_uuid')) {
							params.website_uuid = localStorage.getItem('website_uuid')
						}else{
							params.website_uuid = '';
						}
					}

					pay(params).then(res => {
						const { code, message, data } = res;
						hideLoading();
						if (res.token) {
							this.$store.commit("SET_TOKEN", res.token)
							setToken(res.data.token)
						}
						if (code >= 0) {
							console.log("=========stripe支付成功后登陆=============")
							this.isDisablePayButton = true;
							
							this.checkPayStatus();
						} else {
							let msg = data;
							if (msg == 'Your card has insufficient funds.') {
								msg = '银行卡余额不足，请选择其他支付方式';
							} 

							this.$alert(msg, '支付失败', {
								confirmButtonText: '确定',
								callback: action => {}
							});

							this.isDisablePayButton = false;
						}
					}).catch(err => {
						hideLoading();
						let msg = err.data;
						if (msg == 'Your card has insufficient funds.') {
							msg = '银行卡余额不足，请选择其他支付方式';
						} 

						this.$alert(msg, '支付失败', {
							confirmButtonText: '确定',
							callback: action => {
								
							}
						});

						this.isDisablePayButton = false;
					});
				}
			},
			handleStripeNotify(response) {
				if (response.requires_action) {
					this.stripe.handleCardAction(
						response.client_secret
					).then(res => {
						console.log("======支付确认======");
						console.log(res);
					}).catch(err => {
						console.log(err)
						this.$message.error(err.message)
					});
				}
			},
			changePayType(item,index){
				this.payIndex = index;
				this.payType = item.type;

				/**
				 * if(this.payType == 'stripe'){
					console.log('选择了信用卡支付');
					this.loading = true;
					this.isDisablePayButton = true;
					this.addStripePay(this.payInfo['stripe_key']); //发起网络请求
				}
				 */

				if (this.payType == 'transfer') {
					this.paybutton = vue.$lang('complete_order')
				} else {
					this.paybutton = vue.$lang('pay_button')
				}
			},
			getPayInfo() {
				let params = {
					out_trade_no: this.outTradeNo,
					forceLogin: true
				};
				if(localStorage.getItem('website_uuid')) {
					params.website_uuid = localStorage.getItem('website_uuid')
				}else{
					params.website_uuid = '';
				}
				getPayInfo(params).then(res => {
					const { code, message, data } = res;
					if (data) {
						this.payInfo = res.data;
					}
					this.loading = false;
				}).catch(err => {
					this.loading = false;
					this.$message.error({
						message: err.message,
						duration: 2000,
						onClose: () => {
							this.$router.push({path: '/member/order_list'});
						}
					});
				});
			},
			getPayType() {
				getPayType({}).then(res => {
					const { code, message, data } = res;
					if (code >= 0) {
						if (res.data.pay_type == '') {
							this.payTypeList = [];
						} else {
							let tempPayType = [];
							this.payTypeList.forEach((val, key) => {
								if (res.data.pay_type.indexOf(val.type) != -1) {
									tempPayType.push(val);
									//this.payTypeList.splice(key, 1);
								}
							});
							this.payTypeList = tempPayType;
						}
					}
				}).catch(err => {
					this.$message.error(err.message);
				});
			},
			checkPayStatus() {
				if (!this.timer) {
					this.timer = window.setInterval(() => {
						checkPayStatus({out_trade_no: this.outTradeNo}).then(res => {
							const {code, message, data} = res;
							if (code >= 0) {
								if (code == 0) {
									if (data.pay_status == 2) {
										window.clearInterval(this.timer);
										this.timer = null
										this.dialogVisible = false;
										this.$router.push({
											path: '/result?code=' + this.payInfo.out_trade_no
										});
									}
								} else {
									window.clearInterval(this.timer);
								}
							}
						}).catch(err => {
							window.clearInterval(this.timer);
							this.$router.push({path: '/'});
						});
					}, 2000);
				}
			},
			pay() {
				var payType = this.payTypeList[this.payIndex];
				if (!payType) {
					this.$message({message: '请选择支付方式', type: 'warning'});
					return;
				} 
				
				/**
				if(payType.type == 'stripe'){
					this.stripePay()
					return;
				}
				*/

				if (payType.type == 'stripe' || payType.type == 'paypal'|| payType.type == 'omipaywechat' || payType.type == 'omipayalipay' || payType.type == 'royalpayali' || payType.type == 'royalpaywechat') var newWindow = window.open();
				var params = {
					out_trade_no: this.payInfo.out_trade_no,
					pay_type: payType.type,
					app_type: 'pc'
				};
				if (!this.logined) {
					if (localStorage.getItem('website_uuid')) {
						params.website_uuid = localStorage.getItem('website_uuid')
					} else {
						params.website_uuid = '';
					}
				}
				pay(params).then(res => {
					const { code, message, data } = res;
					if (code >= 0) {
						console.log("=========wx支付成功后登陆=============")
						if (res.token) {
							this.$store.commit("SET_TOKEN", res.token)
							setToken(res.token)
						}
						switch (payType.type) {
							case 'stripe':
								this.payUrl = res.data.url;
								newWindow.location.href = this.payUrl;
								this.open();
								//this.stripePay(res.data)
								break;
							case 'alipay':
								this.payUrl = res.data.data;
								newWindow.location.href = this.payUrl;
								this.open();
								break;
							case 'paypal':
							case 'omipaywechat':
							case 'omipayalipay':
							case 'royalpayali':
							case 'royalpaywechat':
								this.payUrl = res.data.url;
								newWindow.location.href = this.payUrl;
								this.open();
								break;
							case 'wechatpay':
								this.payQrcode = res.data.qrcode;
								this.openQrcode = true;
								break;
							case 'transfer':
								this.$router.push({
									path: '/result?code=' + this.payInfo.out_trade_no
								});
								break;
						}
					} else {
						let msg = vue.$lang('payment_failed_trip');
						this.$alert(msg, vue.$lang('payment_failed'), {
							confirmButtonText: vue.$lang('common.confirm'),
							callback: action => {}
						});
					}
				}).catch(err => {
					let msg = vue.$lang('payment_failed_trip');
					this.$alert(msg, vue.$lang('payment_failed'), {
						confirmButtonText: vue.$lang('common.confirm'),
						callback: action => {}
					});
				});
			},
			open() {
				this.dialogVisible = true;
			},
			otherPayment() {
				clearInterval(this.timer);
				this.dialogVisible = false;
			}, 
			goIndex() {
				clearInterval(this.timer);
				this.dialogVisible = false;
				this.$router.push({
					path: '/'
				});
			},
			goOrderList() {
				clearInterval(this.timer);
				this.dialogVisible = false;
				this.$router.push({
					path: '/member/order_list'
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.clear {
		clear: both;
	}

	.item-block {
		padding: 0 15px 1px;
		margin: 10px 0;
		border-radius: 0;
		border: none;
		background: #ffffff;

		.block-text {
			border-color: #eeeeee;
			color: $ns-text-color-black;
			padding: 7px 0;
			border-bottom: 1px;
		}
	}

	.media-left {
		text-align: center;

		i {
			font-size: 65px;
		}
	}

	.payment-detail {
		padding: 30px 0;
		transition: 2s;
	}

	.media-right {
		text-align: center;
		line-height: 65px;
		cursor: pointer;

		i.rotate {
			transform: rotate(180deg);
			transition: 0.3s;
		}
	}

	.payment-text {
		font-size: 20px;
	}

	.payment-time {
		font-size: 12px;
		line-height: 65px;
		color: #999;
	}

	//支付方式
	.order-submit {
		float: right;
		padding: 10px;
	}

	.pay-type-list {
		padding: 20px 0;
		display: flex;
		flex-direction: column;
	}

	.pay-type-item {
		position: relative;
		display: flex;
		align-items: center;
		// display: inline-block;
		// border: 2px solid #eeeeee;
		border-bottom: 1px solid #eee;
		padding: 5px 20px;
		margin-right: 20px;
		// background-color: pink;
		// width: 180px;
		height: 50px;
		line-height: 50px;
		cursor: pointer;
		font-size: 18px;
		margin-bottom: 15px;
		.pay-type-block {
			width: 100%;
			display: flex;
			align-items: center;
		}
		.iconfont{
			color: #aaa;
			font-size: 30px;
		}
		
		span{
			padding-left: 6px;
			font-weight: 600;
			// display: inline-block;
			// vertical-align: middle;
			// height: 50px;
			// line-height: 50px;
		}
		.pay-desc{
			padding-left: 20px;
			font-size: 14px;
			font-weight: 400;
			color: #666;
		}
	}
	
	.icon-fuxuankuanggou{
		position: absolute;
		display: none;
	}
	
	.icon-zhifubao{
		color: #06B4FD !important;
		font-size: 32px !important;
		padding-left: 30px;
	}
	.icon-weixinzhifu{
		color: #09BB07 !important;
		font-size: 36px !important;
		padding-left: 30px;
	}
	.icon-xinyongka{
		color: #00aaff !important;
		font-size: 36px !important;
		padding-left: 30px;
	}

	.icon-paypal {
		color: #00aaff !important;
		font-size: 30px !important;
		padding-left: 30px;
	}

	.pay-type-item.active {
		// border-color: $base-color;
		.iconfont{
			color: $base-color;
		}
		.icon-fuxuankuanggou{
			display: initial;
		}
	}

	.mobile-wrap {
		width: 300px;
	}

	.order-info {
		.order-item {
			padding: 1px 0;

			.item-label {
				display: inline-block;
				width: 120px;
			}

			.item-value {
				display: inline-block;
			}
		}

		.line {
			width: 100%;
			height: 1px;
			background: #f2f2f2;
			margin: 20px 0 10px 0;
		}

		.order-info-left {
			height: 1px;
		}
	}

	.wechatpay-box {
		text-align: center;

		img {
			width: 80%;
		}
	}

	.confirm-pay-wrap {
		.el-dialog__body {
			padding: 10px 15px;
		}

		.info-wrap {
			i {
				position: initial;
				vertical-align: middle;
				transform: initial;
			}

			span {
				vertical-align: middle;
				padding: 0 10px;
			}
		}
	}
	
	#payment-form{
		// display: none;
		// background-color: #EEE;
		width: 500px;
		margin-top: -35px;
		margin-left: 80px;
		padding: 10px 0;
	}
</style>
<style lang="scss">
	.confirm-pay-wrap {
		.el-dialog__body {
			padding: 10px 15px;
		}

		.el-dialog__footer {
			padding-top: 0;
			padding-bottom: 10px;
		}
	}
	.card-payment {
		background: #f4f4f4;
		padding: 20px 10px;
		border-top: 1px solid #ddd;
		margin: 0 !important;
		.StripeElement{
			width: 400px;
			margin-bottom: 1em;
			box-sizing:border-box;
			height:40px;
			padding:10px 12px;
			border-radius:4px;
			background-color:#fff;
			-webkit-transition:box-shadow 150ms ease;
			transition:box-shadow 150ms ease
		}
		.stripe-save-source {
			display:flex;
			align-items:center;
			justify-content:flex-start;
			.save-source-label {
				display:block;
				padding:0;
				margin:0 0 0 5px;
				font-weight: 400;
				font-size: 16px;
			}
			input[type=checkbox] {
				opacity:0;
				position:absolute;
				width:20px;
				height:20px
			}
			label.checkbox {
				position:relative
			}
			.el-checkbox {
				margin:0px 10px;
			}
			.el-checkbox__inner{
				height:20px;
				width:20px;
				border-radius:3px;
			}
			.el-checkbox__input.is-checked .el-checkbox__inner {
				height:20px;
				width:20px;
				border-radius:3px;
			}
			.el-checkbox__input.is-checked .el-checkbox__inner::after {
				font-size: 30px;
				padding: 1px 2px;
			}
			.el-checkbox__input {
				height:20px;
				width:20px;
				position:relative;
				background-color:#fff;
				display:block;
				border-radius:3px;
			}
		}
	}

	.transfer-payment {
		background: #f4f4f4;
		padding: 20px 10px;
		border-top: 1px solid #ddd;
		margin: 0 !important;
		text-align: left;
	}
</style>
